import { DOCUMENTTYPE_TO_KEYWORDNAMES } from '../util/constants';
import { sortByAttribute, formatDatetime } from '../util/functions';

function transformSchemaToComponent(studentData) {
  let state = {};
  //if changes are made in schema, change the right side of the fields to match the new schema
  state.attemptedHoursPU = studentData?.attemptedHoursPU ?? "";
  state.earnedHoursPU = studentData?.earnedHoursPU ?? "";
  state.administrativeRecommendationConfiguration = studentData?.administrativeRecommendationConfiguration ?? {};
  state.administrativeRecommendationConfiguration.adminReasons = studentData?.administrativeRecommendationConfiguration?.adminReasons ?? "";

  state.scores = {
    act:
      studentData?.scores?.act?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          math: item.math,
          english: item.english,
          science: item.science,
          composite: item.composite,
          reading: item.reading,
        },
      ]) ?? "N/A",
    sat:
      studentData?.scores?.sat?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          math: item.mathSection,
          reading: item.readingTest,
          evidenceBased: item.evidenceBased,
          total: item.total,
        },
      ]) ?? "N/A",
    toefl:
      studentData?.scores?.toefl?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          listening: item.listening,
          speaking: item.speaking,
          reading: item.reading,
          writing: item.writing,
          total: item.total,
        },
      ]) ?? "N/A",
    ielts:
      studentData?.scores?.ielts?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          listening: item.listening,
          speaking: item.speaking,
          reading: item.reading,
          writing: item.writing,
          overall: item.bandscore,
        },
      ]) ?? "N/A",
    accuplacer:
      studentData?.scores?.accuplacer?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          reasoning: item.acuqu ?? "N/A",
          readingNextGen: item.acure ?? "N/A",
          writingNextGen: item.acuwr ?? "N/A",
        },
      ]) ?? "N/A",
    duolingo:
      studentData?.scores?.duolingo?.map((item) => [
        {
          date: item.date && new Date(item.date).toLocaleDateString(),
          comprehension: item.cmprh,
          conversation: item.cnvrs,
          englishTest: item.duoen,
          literacy: item.ltrcy,
          productivity: item.prdct,
        },
      ]) ?? "N/A",
  };

  state.documents =
    studentData?.documents?.map((item) => [
      {
        date: item.date && new Date(item.date).toLocaleDateString(),
        id: item.id,
        city: item.city,
        type: item.type,
        s3: item.s3Object,
        url: item.signedUrl,
        documentType: item.documentType,
      },
    ]) ?? "";
  state.adminAction = studentData?.adminAction;
  state.collegePrep = {
    minimumRequiredConfiguration: {
      foreignLanguage: studentData?.minimumRequiredConfiguration?.foreignLanguage ?? 1, //remove these defaults when we know we have the data
      math: studentData?.minimumRequiredConfiguration?.math ?? 2,
      naturalScience: studentData?.minimumRequiredConfiguration?.naturalScience ?? 3
    },
    earnedForeign: studentData?.collegePrepUnits?.earnedForeign ?? "",
    earnedMath: studentData?.collegePrepUnits?.earnedMath ?? "",
    earnedScience: studentData?.collegePrepUnits?.earnedScience ?? "",
  };
  state.bestTest = {
    score: studentData?.bestTest?.score ?? "",
    optional: studentData?.bestTest?.optional ?? false,
  };
  state.highSchool = {
    art: studentData?.highSchoolUnits?.art ?? "",
    english: studentData?.highSchoolUnits?.english ?? "",
    other: studentData?.highSchoolUnits?.other ?? "",
    socialScience: studentData?.highSchoolUnits?.socialScience ?? "",
    math: studentData?.highSchoolUnits?.math ?? "",
    naturalScience: studentData?.highSchoolUnits?.naturalScience ?? "",
    foreignLanguage: studentData?.highSchoolUnits?.foreignLangauge ?? "",
    ccp: {
      academicAbility: studentData?.highSchoolUnits?.ccp?.academicAbility ?? "",
    },
  };
  state.ccp = {
    academicAbility: studentData?.ccp?.academicAbility ?? "",
    maturityLevel: studentData?.ccp?.maturityLevel ?? "",
    motivation: studentData?.ccp?.motivation ?? "",
  };
  state.committeeDetails = {
    committeeName: studentData?.committee?.value ?? "",
    committeeOptions: studentData?.committee?.options ?? [],
  }
  state.demographics = {
    academicPlan: {
      key: studentData?.academicPlan?.key ?? "",
      value: studentData?.academicPlan?.value ?? "",
    },
    academicProgram: {
      key: studentData?.academicProgram?.key ?? "",
      value: studentData?.academicProgram?.value ?? "",
    },

    admitTerm: studentData?.admitTerm ?? "",
    admitType: studentData?.admitType ?? "",
    applicantEmployeeId: studentData?.applicantEmployeeId ?? "",
    applicationDate: studentData?.applicationDate ? new Date(studentData?.applicationDate).toLocaleDateString() : "",
    buckeyeStudentLeadershipAcademy:
      studentData?.buckeyeStudentLeadershipAcademy ?? "",
    cacrScore: studentData?.cacrScore ?? "",
    campus: studentData?.campus ?? "",
    classRankPercentile: studentData?.classRankPercentile ?? "",
    classRankType: 
               studentData?.classRankType === 'EST' ? 'Estimated' : 
               studentData?.classRankType === 'ACT' ? 'Actual' : 
               studentData?.classRankType ?? "",
    classSize: studentData?.classSize ?? "",
    classStanding: studentData?.classStanding ?? "",
    committeeId: studentData?.committee?.key ?? "",
    county: studentData?.county ?? "",
    dateAssigned: studentData?.dateAssigned ? new Date(studentData?.dateAssigned).toLocaleDateString() : "",
    dateOfBirth: studentData?.dateOfBirth ? new Date(studentData?.dateOfBirth).toLocaleDateString() : "",
    earlyAction: studentData?.earlyAction ?? false,
    evaluationDate: studentData?.evaluationDate ? new Date(studentData?.evaluationDate).toLocaleDateString() : "",
    firstGeneration: studentData?.firstGen ?? false,
    firstName: studentData?.firstName ?? "",
    highSchoolName: studentData?.highSchoolName ?? "",
    highSchoolGPA: studentData?.highSchoolGPA ?? "",
    highSchoolLocation: studentData?.highSchoolLocation ?? "",
    highSchoolGraduationDate: studentData?.highSchoolGraduationDate ? new Date(studentData?.highSchoolGraduationDate).toLocaleDateString() : "",
    homeCountry: studentData?.homeCountry ?? "",
    honors: studentData?.honors ?? false,
    lastName: studentData?.lastName ?? "",
    lowSocioeconomic: studentData?.lowSocioeconomic ?? false,
    middleInitial: studentData?.middleInitial ?? "",
    msp: studentData?.msp ?? false,
    recruitmentCategory: studentData?.recruitmentCategory ?? "",
    onTime: studentData?.onTime ?? false,
    residency: studentData?.residency ?? "",
    residencyCategory: studentData?.residencyCategory ?? "",
    ohioResident: studentData?.residencyCategory?.toLowerCase() === "ohio resident" ? "Yes" : "No",
    rural: studentData?.rural ?? false,
    scholars: studentData?.scholars ?? false,
    siblings: studentData?.siblings ?? "",
    stage: studentData?.stage ?? "",
    assignable: studentData?.assignable,
    inprogressStatus: null,
    status: studentData?.status ?? "",
    reason: studentData?.reason ?? "",

  };

  if(state.demographics.status === "INPROGRESS") {
    state.demographics.inprogressStatus = state.demographics.assignable === true && state.demographics.stage === "1" ? "Not Started" : "In Progress";
  }

  if (state.demographics.evaluationDate)
    state.demographics.evaluationDate = new Date(
      state.demographics.evaluationDate
    ).toLocaleDateString();

  state.notes = {};
  state.notes.competitive = [];
  if (studentData?.reviewNotesOptions?.competitive) {
    for (let quality of studentData?.reviewNotesOptions?.competitive) {
      state.notes.competitive.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }
  }

  state.notes.noncompetitive = [];
  if (studentData?.reviewNotesOptions?.noncompetitive) {
    for (let quality of studentData?.reviewNotesOptions?.noncompetitive) {
      state.notes.noncompetitive.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }
  }

  state.notes.document = [];
  if (studentData?.reviewNotesOptions?.document) {
    for (let quality of studentData?.reviewNotesOptions?.document) {
      state.notes.document.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }
  }

  state.notes.evaluation = [];
  if (studentData?.reviewNotesOptions?.evaluation) {
    for (let quality of studentData?.reviewNotesOptions?.evaluation) {
      state.notes.evaluation.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }
  }

  state.notes.othersConcerns = [];
  if (studentData?.reviewNotesOptions?.othersConcerns) {
    for (let quality of studentData?.reviewNotesOptions?.othersConcerns) {
      state.notes.othersConcerns.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }  
  }

  state.notes.notify = [];
  if (studentData?.reviewNotesOptions?.notify) {
    for (let quality of studentData?.reviewNotesOptions?.notify) {
      state.notes.notify.push({
        label: quality.label,
        order: quality.order,
        parentLabel: quality.parentLabel
      })
    }
  }

  state.restrictions = studentData?.reviewNotesOptions?.restrictions ?? null;
  
  state.priority = studentData?.priority ?? null;
 
  state.readers = studentData?.readers?.map((reader) => {
    return {
      applicantEmployeeId: reader?.applicantEmployeeId,
      adminAction: reader?.adminAction,
      submissionDatetime: reader?.submissionDatetime ? new Date(reader?.submissionDatetime).toLocaleString() : "",
      createdDatetime: reader?.createdDatetime ? new Date(reader?.createdDatetime).toLocaleString() : "",
      readerEmployeeId: reader?.readerEmployeeId,
      firstName: reader?.firstName,
      lastName: reader?.lastName,
      notificationCleared: reader?.notificationCleared,
      notificationCreated: reader?.notificationCreated,
      readerFirstName: reader?.readerFirstName,
      readerLastName: reader?.readerLastName,
      recommendation: reader?.recommendation,
      restriction: reader?.restriction,
      stageNumber: reader?.stageNumber,
      initialApprover: reader?.initialApprover,
      term: reader?.term,
      status: reader?.status,
      data: reader?.data,
      committeeId: reader?.committeeId,
      reviewNotes: {
        competitive: reader?.reviewNotes?.competitive?.map((comp) => ({
          label: comp.label,
          order: comp.order,
          disableActions: comp.disableActions,
          parentLabel: comp.parentLabel
        }) ?? []),
        noncompetitive: reader?.reviewNotes?.noncompetitive?.map((comp) => ({
          label: comp.label,
          order: comp.order,
          disableActions: comp.disableActions,
          parentLabel: comp.parentLabel
        }) ?? []),
        document: reader?.reviewNotes?.document?.map((comp) => ({
          label: comp.label,
          order: comp.order,
          disableActions: comp.disableActions,
          parentLabel: comp.parentLabel
        }) ?? []),
        evaluation: reader?.reviewNotes?.evaluation?.map((comp) => ({
          label: comp.label,
          order: comp.order,
          disableActions: comp.disableActions,
          parentLabel: comp.parentLabel
        }) ?? []),
        othersConcerns: reader?.reviewNotes?.othersConcerns?.map((comp) => ({
          label: comp.label,
          order: comp.order,
          disableActions: comp.disableActions,
          parentLabel: comp.parentLabel
        }) ?? []),
        submissionDatetime:
          reader.submissionDatetime &&
          new Date(reader.submissionDatetime)?.toLocaleDateString()
      }
    }
  }
  )

  // state.recommendationHistory = studentData?.recommendationHistory; no

  //map out recommendationHistory individually so that we can modify as needed for schema changes etc. 
  // don't ask me where I got this code ;) 
  const recHistory = studentData?.recommendationHistory?.map((item) => ({
    reviewerEmployeeId: item.reviewerEmployeeId,
    stage: item.stage,
    assignTime: item.assignTime,
    submitTime: item.submitTime,
    firstName: item.firstName,
    lastName: item.lastName,
    notificationCleared: item?.notificationCleared,
    notificationCreated: item?.notificationCreated,
    reason: item.reason,
    type: item.type,
    recommendation: item.recommendation === "Cancelled" && item.reason ? item.reason : item.recommendation,
    restriction: item.restriction,
    comments: {
      adminOverride: item.comments?.adminOverride,
      competitive: Array.isArray(item.comments?.competitive)
        ? item.comments?.competitive.map((c) => ({ label: c.label }))
        : [],
      noncompetitive: Array.isArray(item.comments?.noncompetitive)
        ? item.comments?.noncompetitive.map((c) => ({ label: c.label }))
        : [],
      document: Array.isArray(item.comments?.document)
        ? item.comments?.document.map((c) => ({ label: c.label }))
        : [],
      evaluation: Array.isArray(item.comments?.evaluation)
        ? item.comments?.evaluation.map((c) => ({ label: c.label }))
        : [],
      othersConcerns: Array.isArray(item.comments?.othersConcerns)
        ? item.comments?.othersConcerns.map((c) => ({ label: c.label }))
        : [],
      notify: Array.isArray(item.comments?.notify)
        ? item.comments?.notify.map((c) => ({ label: c.label }))
        : []
    },
  }));
  
  const notesConfigChildren = [
        ...state.notes.evaluation,
        ...state.notes.document,
        ...state.notes.notify,
    ]
    .filter(note => note.parentLabel)
    .map(note => note.label);

  state.recommendationHistory = getUnifiedRecommendationHistoryTable(recHistory, state.readers, notesConfigChildren);

  //add incoming reviewNotes

  state.universityInfo = {
    cumGPA: studentData?.cumulativePUGPA ?? "",
    schools: studentData?.universityInfo?.map((item) =>
    ({
      attemptedUnits: item.attemptedUnits,
      name: item.name,
      city: item.city,
      completedUnits: item.completedUnits,
      country: item.country,
      state: item.state,
      cumGPA: item.cumGPA,
      fromDate: item.fromDate,
      toDate: item.toDate
    }),
    ) ?? ""
  };

  state.countryOfCitizenship = studentData?.countryOfCitizenship ?? [];
  state.decision = studentData?.decision ?? null;
  state.decisionOverride = studentData?.decisionOverride ?? null;
  state.holdForExport = studentData?.holdForExport ?? null;

  return state;
}

function transformDocumentList(docData) {
  if (!Array.isArray(docData)) {
    return docData;
  }

  let state = [];

  for (let doc of docData) {
    let newItem = {
      documentType: doc.documentType.split('ADM')[1].trim(), // Primarily needed for the Application document
      documentId: doc.documentID,
      documentDate: new Date(doc.documentDate).toLocaleDateString(),
      documentDateOriginal: doc.documentDate,
      documentName: doc.documentName,
      orgName: doc.keywords.find((keyword) => {
        return keyword.keywordName === "Org Name"
      })?.keywordValue
    };
    
    newItem.displayLabel = newItem.documentType; // Default we show on screen

    if (newItem.orgName && newItem.documentType.includes("University Document")) {
      newItem.documentType += " - " + newItem.orgName;
    }
    
    // Set display labels from keyword
    let keywordNames = DOCUMENTTYPE_TO_KEYWORDNAMES[doc.documentType];
    
    if (Array.isArray(keywordNames)) {
        keywordNames.forEach(keywordName => {
           const keyword = doc.keywords.find(keyword => keyword.keywordName === keywordName);
           if (keyword) {
                newItem.keyword = keyword.keywordValue;
                newItem.displayLabel = keyword.keywordValue;
            }
        });
    }

    newItem.sortOrder = getSortOrder(newItem.displayLabel?.toUpperCase?.() ?? "");
    state.push(newItem);
  }

  state.sort((doc1, doc2) => {
    if (doc1.sortOrder < doc2.sortOrder) { return -1; }
    if (doc1.sortOrder > doc2.sortOrder) { return 1; }
    if (doc1.documentType < doc2.documentType) return -1;
    if (doc1.documentType > doc2.documentType) return 1;
    if (doc1.documentDateOriginal > doc2.documentDateOriginal) return -1;
    if (doc1.documentDateOriginal < doc2.documentDateOriginal) return 1;
    return 0;
  });

  return state;
}

function getSortOrder(displayLabel) {  
    const order = {
        "SCHOOL REPORT"         : 0,
        "HS PROFILE"            : 1,
        "HS TRANSCRIPT"         : 2,
        "UNIVERSITY TRANSCRIPT" : 3,
        "APPLICATION"           : 4,
        "RECOMMENDATION"        : 5,
    };
    
    return order[displayLabel] ?? 1000; // Shove to the end
}

function transformReviewer(reviewers) {

  let state = [];
  for(let item of reviewers) {
    const { admin, reviewerEmployeeId, firstName, lastName, campusId, jobType, experienceLabel } = item;
    state.push({
      admin: admin,
      emplid: reviewerEmployeeId,
      firstName: firstName,
      lastName: lastName,
      campusId: campusId,
      jobType: jobType,
      experienceLabel: experienceLabel,
    })
  }
  
   // Sort the state array by lastName before returning it
   return sortByAttribute(state, 'lastName');
}

// Create unified history from recommendation history and training history for simpler display logic
function getUnifiedRecommendationHistoryTable(recHistory, reviewerHistory, notesConfigChildren) {
  const isTraining = window.location.pathname.startsWith("/training"); // Alternatively pass useLocation from component -> container -> actions -> transform -> here
  let historyTableData = JSON.parse(JSON.stringify(recHistory ?? reviewerHistory ?? []));
  
  // Format each field
  historyTableData.forEach((recommendationRow = {}) => {
    const useFullName = recommendationRow.firstName && recommendationRow.lastName ? true : false;
    const fullName = `${recommendationRow.firstName} ${recommendationRow.lastName}`;
    const hiddenName = `Reviewer ${recommendationRow.stage}`;
    const fullTrainingName = `${recommendationRow.readerFirstName} ${recommendationRow.readerLastName}`;

    recommendationRow.nameFormatted = isTraining ? fullTrainingName : (useFullName ? fullName : hiddenName);
    recommendationRow.assignTimeFormatted = formatDatetime(isTraining ? recommendationRow.createdDatetime : recommendationRow.assignTime);
    recommendationRow.submitTimeFormatted = formatDatetime(isTraining ? recommendationRow.submissionDatetime : recommendationRow.submitTime);
    recommendationRow.recommendationUnified = getRecommendationUnified(recommendationRow, isTraining);
    recommendationRow.adminOverrideComment = recommendationRow.comments?.adminOverride;
    recommendationRow.comments = getUnifiedNotesSections(recommendationRow.comments ?? recommendationRow.reviewNotes, notesConfigChildren); // Training is under .reviewNotes
  });
  
  return historyTableData;
}

function getRecommendationUnified({ recommendation, status }, isTraining) {
  if (!isTraining) { return recommendation; }

  // Training
  if (recommendation) { return recommendation; }
  if (status?.includes?.('TRAINING_UNASSIGNED')) { return 'Unassigned'; }
  
  return 'Pending';
}

function getUnifiedNotesSections(comments = {}, notesConfigChildren) {
    const getSectionFn = getSection.bind(notesConfigChildren);
    const notesSectionsToDisplay = [
        getSectionFn("Competitive Attributes"       , comments.competitive),
        getSectionFn("Non-Competitive Attributes"   , comments.noncompetitive),
        getSectionFn("Administrative Notes"         , [ ...comments?.othersConcerns ?? [], ...comments?.evaluation ?? [], ...comments?.document ?? [] ], true),
        getSectionFn("Notify Admin"                 , comments.notify),
    ];

    return notesSectionsToDisplay;
}

function getSection(title, comments, indentable = false) {
    return { title, notes: getNotes(comments, this, indentable), hasNotes: comments?.length > 0 };
}

function getNotes(notes, notesConfigChildren, indentable = false) {
    notes = notes?.map(note => ({ label: note.label }));
    if (!(notes?.length > 0)) { return [{ label: "None" }]; }
    
    if (indentable) {
        notes = notes.map(note => ({
            ...note,
            shouldIndent: notesConfigChildren.includes(note.label)
        }));
    }
    
    return notes;
}

export { transformSchemaToComponent, transformDocumentList, transformReviewer };
